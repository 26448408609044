import React from 'react'
import { AdvertisingContainer } from './Advertising.styles'

function Advertising() {
  return (
    <AdvertisingContainer>
      Espacio de publicidad relacionada al servicio
    </AdvertisingContainer>
  )
}

export default Advertising
