import React, { useEffect } from 'react';
import is from 'is_js';
import { Outlet } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';
import { Chart as ChartJS, registerables } from 'chart.js';
// import { CloseButton } from 'components/common/Toast';
// import SettingsToggle from 'components/settings-panel/SettingsToggle';
// import SettingsPanel from 'components/settings-panel/SettingsPanel';
import { useAppContext } from 'providers/AppProvider';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { MenuProvider } from './menuContext';
import { ModalProvider } from 'modalContext';
import { PropertyProvider } from './propertyContext';
import { SearchProvider } from 'searchContext';
import { FilterProvider } from './filterContext';

ChartJS.register(...registerables);

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition },
  } = useAppContext();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <FilterProvider>
        <SearchProvider>
          <PropertyProvider>
            <MenuProvider>
              <ModalProvider>
                <Outlet />
              </ModalProvider>
            </MenuProvider>
          </PropertyProvider>
        </SearchProvider>
      </FilterProvider>
      {/* <SettingsToggle />
      <SettingsPanel />
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position="bottom-left"
      /> */}
    </>
  );
};

export default App;
